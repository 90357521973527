<template>
  <section class="dtc-main-section mt-4" id="">
    <div
      class="dtc-print"
      v-for="(topItem, topIndex) in dtcGridSize"
      :key="topIndex"
      style="page-break-after:always;pointer-events:none;"
      :sty2le="topIndex > 1 ? 'page-break-after:always;' : ''"
    >
      <section class="dtc-print7-grid-header">
        <span
          class="dtc-title"
          v-if="topIndex == 0"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
          >{{ schoolName }}學校{{ bYear }}學年度第{{
            term
          }}學期「重點科別培育公費醫師制度計畫」公費生
          待遇補助經費申請表</span
        >
        <div>序號</div>
        <div class="dtc-fill-required2">年級</div>
        <div class="dtc-fill-required">學期</div>
        <div class="dtc-fill-required">人數</div>
        <section class="sec1">
          <header>註冊費</header>
          <div style="text-align: center;">學雜費</div>
          <div>學生平安保險費</div>
          <div>
            其他相關費用
            <span
              style="font-size : 12px;  transform : scale(0.85); display:inline-block; margin-top:-7px;"
              >(如語言實習或電腦使用費等， 請增列或備註說明)</span
            >
          </div>
          <footer class="pt-3 pl-3 dtc-fill-required2">
            請檢附貴校註冊各項單價表，以供核對
          </footer>
        </section>
        <section class="sec2">
          <header>生活費</header>
          <div style="text-align: center;">膳食費</div>
          <div style="text-align: center;">零用津貼</div>
          <!--<div style="text-align: center;">課業費</div>-->
          <div style="text-align: center;">書籍費</div>
          <div style="text-align: center;">制服費</div>
          <div style="text-align: center;">旅行參訪費</div>
          <footer>
            <div>每名每學期19,500元</div>
            <div>每名每學期21,000元</div>
            <!--<div>每名每學期1,000元</div>-->
            <div>每名每學期4,000元</div>
            <div>每名每學期2,500元</div>
            <div>應屆畢業生3,000元/人</div>
          </footer>
        </section>
        <section class="sec3">
          <header>旅宿費</header>
          <!--<div style="text-align: center;">返鄉旅費</div>-->
          <div style="text-align: center;">住宿費</div>
          <footer>
            <!--<div>可依第4期計畫每名5,000元或實際狀況預估</div>-->
            <div>每名每學期3,000元/月/*6個月=18,000元</div>
          </footer>
        </section>
        <section class="sec4">
          <header style="border-bottom:0px"></header>
          <div style="border-top:0px;padding-left:5px;text-align: center;">
            設備費
          </div>
          <footer>
            <div>
              <p>每學期 : 每名15,000元</p>
            </div>
          </footer>
        </section>
        <section class="sec4">
          <header style="border-bottom:0px"></header>
          <div style="border-top:0px;padding-left:5px;text-align: center;">
            管理費
          </div>
          <footer>
            <div>
              <p>每人每學期6,500元</p>
            </div>
          </footer>
        </section>
        <div style="border-right:0">小計</div>
      </section>
      <main
        v-for="(item, idx) in pageRows.slice(
          topIndex * pageSize,
          pageSize + topIndex * pageSize
        )"
        :key="item.myKey"
        class="dtc-table"
      >
        <div
          class="dtc-remove"
          v-if="item.where"
          @click="removeRow(item)"
          hidden
        >
          X
        </div>
        <div class="pt-1">{{ idx + 1 + topIndex * pageSize }}</div>

        
        <div class="dtx-tooltip">
          <div class="dtx-tip" v-if="!item.year"></div>
          <b-form-select
            style="font-size:12px;background:var(--light);color:black;border:none;padding-left:0px;"
            v-model="item.year"
            class="dtx-select d-print-none"
            :options="level6"
            @change="updateYear(item)"
            :disabled="!item.where && !debug"
          ></b-form-select>
          <span
            class="d-none d-print-inline-block"
            style="border:none !important;font-size:16px; !important;padding:3px"
            >{{ item.year }}</span
          >
        </div>
        <div>{{ item.term }}</div>
        <div>
          <div class="pt-1" style="height:29px; border-right:none;">
            {{ item.persons }}
          </div>
        </div>
        <section class="dtc-s1" style="border:none;">
          <div>
            <div class="text-right-mode">
              {{ $formatPrice(item.learnSpend$) }}
            </div>
          </div>
          <div>
            <div class="text-right-mode">{{ $formatPrice(item.insure$) }}</div>
          </div>
          <div>
            <div class="text-right-mode">{{ $formatPrice(item.others$) }}</div>
          </div>
        </section>
        <section class="dtc-s2" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(item.eat$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.allow$) }}</div>
          <!--<div class="text-right-mode">{{ $formatPrice(item.class$) }}</div>-->
          <div class="text-right-mode">{{ $formatPrice(item.book$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.cloth$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.travel$) }}</div>
        </section>
        <section class="dtc-s3" style="border:none;">
          <!--<div class="text-right-mode">{{ $formatPrice(item.backHome$) }}</div>-->
          <div class="text-right-mode">{{ $formatPrice(item.live$) }}</div>
        </section>
        <section class="dtc-s4" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(item.device$) }}</div>
        </section>
        <section class="dtc-s4" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(item.manageFee$) }}</div>
        </section>
        <div
          style="border:none; text-align:right;padding-top:5px;padding-right:5px;"
        >
          {{ $formatPrice(item.rowTotal) }}
        </div>
        <span
          style="border:none;"
          class="dtc-page d-none d-print-none"
          v-if="idx + 1 == pageSize && topIndex + 1 != dtcGridSize"
        >
          {{ `第${topItem}/${dtcGridSize}頁` }}
        </span>
      </main>
      <footer class="dtc-footer-total" v-if="topItem === dtcGridSize">
        <div class="dtx-count">小計</div>
        <section class="dtc-s1" style="border:none;">
          <div style="padding-top:5px;" class="text-right-mode">
            {{ $formatPrice(learnSpendFooter) }}
          </div>
          <div style="padding-top:5px;" class="text-right-mode">
            {{ $formatPrice(insureFooter) }}
          </div>
          <div style="padding-top:5px;" class="text-right-mode">
            {{ $formatPrice(othersFooter) }}
          </div>
        </section>
        <section class="dtc-s2" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(eatFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(allowFooter) }}</div>
          <!--<div class="text-right-mode">{{ $formatPrice(classFooter) }}</div>-->
          <div class="text-right-mode">{{ $formatPrice(bookFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(clothFooter) }}</div>
          <div class="text-right-mode">{{ $formatPrice(travelFooter) }}</div>
        </section>
        <section class="dtc-s3" style="border:none;">
          <!--<div class="text-right-mode">{{ $formatPrice(backHomeFooter) }}</div>-->
          <div class="text-right-mode">{{ $formatPrice(liveFooter) }}</div>
        </section>
        <section class="dtc-s4" style="border:none;">
          <div>
            <div class="text-right-mode">{{ $formatPrice(deviceFooter) }}</div>
          </div>
        </section>
        <section class="dtc-s4" style="border:none;">
          <div>
            <div class="text-right-mode">{{ $formatPrice(manageFeeFooter) }}</div>
          </div>
        </section>
        <div
          style="border:none;padding-top:5px;text-align:right;padding-right:5px;"
        >
          {{ $formatPrice(sumFooter) }}
        </div>
        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color);text-align: left;border-bottom:1px solid var(--dtc-border-color);min-height:20px;padding-left:5px"
          class="pt-2"
        >
          {{ supplement }}
        </div>

        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border:none">總計</div>
        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total"></div>
        <div
          class="dtx-total"
          style="border:none;padding-top:5px;text-align:right;padding-right:5px;"
        >
          {{ $formatPrice(sumFooter) }}
        </div>
        <div
          style="grid-column: 1/ -1;;border:none;border-top:1px solid var(--dtc-border-color);text-align: center;border-bottom:0px solid var(--dtc-border-color);"
          class="pt-2"
        >
          本次預估申請補助費用，經常門：{{
            $formatPrice(sumFooter - deviceFooter)
          }}元 ; 資本門：{{ $formatPrice(deviceFooter) }}元 ; 管理費：{{ $formatPrice(manageFeeFooter) }}元，合計{{
            $formatPrice(sumFooter)
          }}元。
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import html2canvas from "html2canvas";
import queryString from "query-string";
const rowObj = {};
let pageRows = new Array().fill().map((s, t) => {
  return {
    myKey: t,
    device$: "",
    insure$: "",
    others$: "",
    persons: "",
    live$: "",
  };
});

export default {
  name: "totalSpend",
  data() {
    return {
      twYears,
      majors: [],
      term: 1,
      bYear: "108",
      category: [],
      schoolName: "",
      map: "",
      level6,
      dtcGridSize: 1,
      pageRows,
      learnSpendFooter: 0,
      insureFooter: 0,
      othersFooter: 0,
      eatFooter: 0,
      allowFooter: 0,
      classFooter: 0,
      bookFooter: 0,
      clothFooter: 0,
      travelFooter: 0,
      backHomeFooter: 0,
      liveFooter: 0,
      deviceFooter: 0,
      manageFeeFooter: 0,
      sumFooter: 0,
      pageSize: 14,
      debug: window.dtcDebug,
      supplement: "",
    };
  },

  methods: {
    resetRows() {
      this.pageRows = new Array(200).fill().map((s, t) => {
        return {
          myKey: t,
          learnSpend$: "",
          insure$: "",
          others$: "",
          persons: "",
          travel$: "",
          backHome$: "",
          live$: "",
        };
      });
    },
    async removeRow(item) {
      await this.save("remove", item.Bno);
      setTimeout(() => this.getData(), 300);
    },
    updateYear(item) {
      const len = this.pageRows.length;
      const arr = this.pageRows;
      if (!len) return;
      for (let i = 0; i < len; ++i) {
        if (
          item.myKey != i &&
          this.pageRows[i].where &&
          this.pageRows[i].year &&
          this.pageRows[i].where === item.where &&
          this.pageRows[i].year === item.year
        ) {
          const cat = this.category.find((s) => s.value == item.where);
          let txt = "暫無資料";
          if (cat) txt = cat.text;
          let str = "同科系年級不能重複: " + txt + item.year + "年級";
          this.$root.$emit("dtc-server-error", str);
          item.year = null;
          this.pageRows = [...this.pageRows];
          break;
        }
      }
    },
    rowPersonUpdate(item) {
      if (isNaN(item.persons) || item.persons < 0) return;
      const n = item.persons;
      item.eat$ = 19500 * n;
      item.allow$ = 21000 * n;
      //item.class$ = 1000 * n;
      item.book$ = 4000 * n;
      item.cloth$ = 2500 * n;
      ////////////////////////
      //item.travel$ = 3000 * n;
      // item.backHome$ = 5000 * n;
      // item.live$ = 18000 * n;
      /////////////
      item.manageFee$ = 6500 * n;
      item.rowTotal = 0;
      Object.keys(item).forEach((s) => {
        if (s.includes("$") && !isNaN(item[s])) {
          item.rowTotal += Number(item[s]);
        }
      });
      this.learnSpendFooter = this.insureFooter = this.othersFooter = 0;
      this.travelFooter = this.clothFooter = this.bookFooter = this.classFooter = this.allowFooter = this.eatFooter = 0;
      this.backHomeFooter = this.liveFooter = this.deviceFooter = this.manageFeeFooter = 0;
      this.sumFooter = 0;
      this.pageRows.forEach((s) => {
        //////////////////////////////////////////////////
        this.learnSpendFooter +=
          Number(s.learnSpend$) > 0 ? Number(s.learnSpend$) : 0;
        this.insureFooter += Number(s.insure$) > 0 ? Number(s.insure$) : 0;
        this.othersFooter += Number(s.others$) > 0 ? Number(s.others$) : 0;
        ////////
        this.eatFooter += s.eat$ > 0 ? s.eat$ : 0;
        this.allowFooter += s.allow$ > 0 ? s.allow$ : 0;
        //this.classFooter += s.class$ > 0 ? s.class$ : 0;
        this.bookFooter += s.book$ > 0 ? s.book$ : 0;
        this.clothFooter += s.cloth$ > 0 ? s.cloth$ : 0;
        /////////////////////////////////////////
        this.travelFooter += +s.travel$ > 0 ? +s.travel$ : 0;
        //this.backHomeFooter += +s.backHome$ > 0 ? +s.backHome$ : 0;
        this.liveFooter += +s.live$ > 0 ? +s.live$ : 0;
        /////
        this.deviceFooter += +s.device$ > 0 ? +s.device$ : 0;
        this.manageFeeFooter += s.manageFee$ > 0 ? s.manageFee$ : 0;
        this.sumFooter += s.rowTotal > 0 ? s.rowTotal : 0;
      });
    },
    async save(remove, Bno) {
      if (!this.map) {
        this.$root.$emit(
          "dtc-server-error",
          "Cannot save due to previous API, Budgeting/Get? fail  from server"
        );
        return;
      }
      let url = "Budgeting/Put?id=" + this.map.SeqNo;
      try {
        //alert(this.map.budgetings.length);
        this.deNormalizeData();
        if (Bno) {
          this.map.budgetings = this.map.budgetings.filter((s) => s.Bno != Bno);
        }
        //alert(this.map.budgetings.length);
        await window.axios.put(url, this.map);
        this.$root.$emit("dtc-save-form-ok");
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
    deNormalizeData() {
      const obj = { budgetings: "" };
      if (this.map) delete this.map.budgetings;
      else return null;
      let arr = [];

      for (let i = 0; i < this.pageRows.length; ++i) {
        if (!this.pageRows[i].where) break;
        let obj = {};
        const item = this.pageRows[i];
        obj.SeqNo = this.map.SeqNo;
        obj.Bno = item.Bno;
        obj.MajorId = item.where;
        let idx = this.level6.findIndex((s) => s === item.year);
        obj.Grade = item.year;
        obj.NumberOfPeople = +item.persons;
        obj.Fee_1 = +item.learnSpend$;
        obj.Fee_2 = +item.insure$;
        obj.Fee_3 = +item.others$;
        obj.Fee_4 = +item.eat$;
        obj.Fee_5 = +item.allow$;
        //obj.Fee_6 = +item.class$;
        obj.Fee_7 = +item.book$;
        obj.Fee_8 = +item.cloth$;
        obj.Fee_9 = item.travel$ ? +item.travel$ : 0;
        //obj.Fee_10 = item.backHome$ ? +item.backHome : 0;
        obj.Fee_11 = item.live$ ? +item.live$ : 0;
        obj.Fee_12 = item.device$ ? +item.device$ : 0;
        obj.Fee_13 = item.manageFee$ ? +item.manageFee$ : 0;
        obj.Subtotal = +item.rowTotal;
        arr.push(obj);
      }
      this.map.budgetings = arr;
      return this.map;
    },
    normalizeData(map) {
      let bugs = map && map.budgetings ? map.budgetings : [];
      if (!bugs.length) return;
      //bugs = [...bugs, ...bugs, ...bugs];
      this.oldRowNum = bugs.length;
      // alert(bugs.length);
      bugs.forEach((s, i) => {
        let item = this.pageRows[i];
        item.Bno = s.Bno;
        item.where = s.MajorId;
        // item.whereTip = this.category.find((s) => s.value == item.where).text;
        item.year = s.Grade;
        item.term = s.Term;
        item.persons = s.ApplyOfPeople;
        item.learnSpend$ = s.Fee_1;
        item.insure$ = s.Fee_2;
        item.others$ = s.Fee_3;

        item.eat$ = s.Fee_4; //s.ApplyOfPeople * 19500;
        item.allow$ = s.Fee_5; //s.ApplyOfPeople * 21000;
        //item.class$ = s.Fee_6; //s.ApplyOfPeople * 1000;
        item.book$ =  s.Fee_7; //s.ApplyOfPeople * 4000;
        item.cloth$ = s.Fee_8; //s.ApplyOfPeople * 2500;
        item.travel$ = s.Fee_9 ? "" + s.Fee_9 : "";
        //item.backHome$ = s.Fee_10 ? "" + s.Fee_10 : ""; //s.Fee_10;
        item.live$ = s.Fee_11 ? "" + s.Fee_11 : ""; //s.Fee_11;
        item.device$ = s.Fee_12 ? "" + s.Fee_12 : "";
        item.manageFee$ = s.Fee_13; //s.ApplyOfPeople * 6500;
        item.rowTotal = s.Subtotal;
      });
      let size = bugs.length > this.pageSize ? bugs.length / this.pageSize : 1;
      size = size > 0 ? parseInt(size) : 1;
      const mod = size > 1 && bugs.length % this.pageSize > 0 ? 1 : 0;
      this.dtcGridSize = mod + size;
      this.dtcGridSize += size === 1 && bugs.length > this.pageSize ? 1 : 0;
      //alert(this.dtcGridSize);
      this.pageRows = [...this.pageRows];
      console.log('rowPersonUpdate',this.pageRows[0]);
      this.rowPersonUpdate(this.pageRows[0]);
    },
    async getData() {
      this.learnSpendFooter = this.infsureFooter = this.othersFooter = 0;
      this.travelFooter = this.clothFooter = this.bookFooter = this.classFooter = this.allowFooter = this.eatFooter = 0;
      this.backHomeFooter = this.liveFooter = this.deviceFooter = this.manageFeeFooter = 0;
      this.sumFooter = 0;
      this.resetRows();
      const {
        schoolcode: SchoolCode = "0906",
        byear: Byear = "109",
        term = 1,
        category: Category = "S",
      } = queryString.parse(location.href.split("?")[1]);
      this.term = +term;
      const url = SchoolCode
        ? `Budgeting/Get?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`
        : "Budgeting/Get?schoolcode=0906&byear=108&term=1&category=S";
      let map = "";
      try {
        map = await window.axios.get(url);
        if (Array.isArray(map)) {
          map = map[0];
        }
        console.log(map);
        if (map) {
          this.map = map;
          this.pageSize = this.map.budgetings.length;
          this.supplement = map.Supplement;
          this.normalizeData(this.map);
        } else {
          //alert(`error server api ${url} return 200 but the data is: ` + map);
          const str = `error server api ${url} return status 200 but the data is null`;
          this.$root.$emit("dtc-server-error", str);
          //this.pageRows = [];
          // user can enter data into sheet; not need to empty rows
        }
      } catch (e) {
        this.debug = true;
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  async mounted() {
    const { schoolcode, byear, term, category } = queryString.parse(
      location.href.split("?")[1]
    );
    this.term = +term;
    this.bYear = +byear;

    //for check is same school-prevent see other school
    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolcode) {
        return;
      }
    }

    //check Can see and then get data
    this.getData();
  },
  async beforeMount() {
    const schools = JSON.parse(localStorage.dtcSchoolList);
    const {
      schoolcode: SchoolCode = "0906",
      byear: Byear = "109",
      term = 1,
      category: Category = "S",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    const { SchoolName } = schools.find((s) => s.SchoolCode == SchoolCode);
    this.schoolName = SchoolName;
    let arr = [];
    let url = `Major/Get?schoolcode=${SchoolCode}`;
    try {
      arr = await window.axios.get(`Major/Get?schoolcode=${SchoolCode}`);
    } catch (e) {
      const str = e + " API: " + url;
      this.$root.$emit("dtc-server-error", str);
    }
    let majors = [];
    let arr2 = [];
    arr.forEach((s) => {
      majors.push(`${s.MajorName}--${s.MajorId}`);
      arr2.push({ value: s.MajorId, text: `${s.MajorName}` });
    });
    this.category = arr2;
    this.majors = majors;
  },
};
</script>
<style lang="scss">
main .custom-select {
  background: none;
}
</style>
<style lang="scss">
.dtc-main-section ::v-deep .dropdown-toggle {
  min-width: 57px !important;
  font-size: 14px;
  background: white;
  color: black;
  &::after {
    all: unset;
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-fill-requiredxxx,
.dtc-fill-required2xxx {
  position: relative;
  &::after {
    position: absolute;
    font-size: 24px;
    color: red;
    content: "*";
    top: -5px;
    left: 10px;
  }
}
.dtc-fill-required2xxx::after {
  top: -5px;
  left: 70px;
}
.dtc-print {
  position: relative;
  --dtc-border-color: var(--dark);
  margin-top: 30px;
  margin: 30px auto 2px auto;
  width: 1302px;
  margin-bottom: 60px;
  .input1 {
    border: none;
    max-width: 49px;
    padding: 3px;
  }
  .input2 {
    border: none;

    // height: 30px;
    display: inline-block;
    padding: 3px;
  }
}
.dtc-footer-d1 {
  display: grid;
  place-items: center;
  > div {
    display: grid;
    grid-template-columns: max-content 220px max-content 220px max-content 220px max-content;
    grid-gap: 12px;
  }
}

.dtc-page {
  position: absolute;
  bottom: 0;
  right: 0px;
  transform: translateY(65px);
  border: none;
}
.dtc-add {
  position: absolute;
  bottom: 0;
  right: 0px;
  transform: translateX(80px);
  border: none;
}
.dtc-title {
  position: absolute;
  // top: -10px;
  left: 50%;
  display: inline-block;
  text-align: center;
  width: 1302px;
  transform: translate(-50%, -30px);
  font-size: 16px;
  font-weight: bold;
}

.dtc-print7-grid-header {
  position: relative;
  z-index: 13px;
  font-size: 12px !important;
  display: grid;
  padding: 0px;
  border: 1px solid var(--dtc-border-color);
  width: max-content;
  grid-template-columns: 50px 60px 100px 50px 270px 450px 110px 110px 80px 110px;
  > div {
    height: 220px;
    border-right: 1px solid var(--dtc-border-color);
    line-height: 220px;
    text-align: center;
  }
  .sec1,
  .sec2,
  .sec3,
  .sec4 {
    z-index: 2px;
    display: grid;
    height: 220px;
    grid-template-columns: repeat(3, 1fr);
    header {
      grid-column: 1 / -1;
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      text-align: center;
      height: 30px;
    }
    > div {
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      padding: 3px;
      height: 80px;
    }
    footer {
      grid-column: 1 / -1;
      height: calc(220px - 110px);
      border-right: 1px solid var(--dtc-border-color);
    }
  }
  .sec2 {
    grid-template-columns: repeat(5, 90px);
    footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(5, 90px);
      border: none;
      > div {
        padding: 3px;
        border-right: 1px solid var(--dtc-border-color);
        //line-height: calc(220px - 170px);
      }
    }
  }

  .sec3 {
    grid-template-columns: repeat(1, 1fr);
    footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      border: none;
      > div {
        padding: 3px;
        border-right: 1px solid var(--dtc-border-color);
        //line-height: calc(220px - 170px);
      }
    }
  }
  .sec4 {
    grid-template-columns: 1fr;
    footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: 1fr;
      border: none;
      > div {
        padding: 3px;
        border-right: 1px solid var(--dtc-border-color);
        //line-height: calc(220px - 170px);
      }
    }
  }
}

.dtc-remove {
  position: absolute;
  top: 0px;
  left: -20px;
  color: red;
  // border-right: none !important;
  //width: 16px;
  //height: 16px;
  font-size: 13px;
  text-align: center;
  // line-height: 25px;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid red;
}
.dtc-table,
.dtc-footer-total {
  text-align: center !important;
  position: relative;
  border-left: 1px solid var(--dtc-border-color);
  border-bottom: 1px solid var(--dtc-border-color);
  border-right: 1px solid var(--dtc-border-color);
  font-size: 12px !important;
  display: grid;
  width: 1392px;
  //50px repeat(2, 60px) 50px 270px 360px 250px 80px;
  grid-template-columns: 50px 60px 100px 50px 270px 450px 110px 110px 80px 110px;
  > * {
    // min-height: 30px !important;
    // max-height: 30px !important;
    border-right: 1px solid var(--dtc-border-color);
    font-size: 16px;
    > * {
      border-right: 1px solid var(--dtc-border-color);
      // min-height: 30px !important;
      // max-height: 30px !important;
    }
  }
}

.dtc-footer-total {
  grid-template-columns: 260px 270px 450px 110px 110px 80px 110px;
  .dtx-count {
    text-align: center;
    // line-height: 30px;
    padding-top: 0px;
  }
}
.dtx-total {
  grid-template-columns: 1118px 80px;
}

.dtc-s1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  > div {
    padding-top: 5px;
  }
}
.dtc-s2 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  > div {
    padding-top: 5px;
  }
}

.dtc-s3 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  > div {
    padding-top: 5px;
  }
}
.dtc-s4 {
  display: grid;
  grid-template-columns: 1fr;
  > div {
    padding-top: 5px;
  }
}

.dtc-sign-area {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 38px 60px;
  > div {
    padding-top: 5px;
  }
}
.dtc-note-area {
  min-height: 60px !important;
  text-align: left;
  > div {
    border-right: none;
    padding-left: 4px;
  }
}
.text-right-mode {
  text-align: right;
  padding-right: 5px;
}
@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-print {
    --dtc-border-color: var(--dark);
    // max-width: 98vw;
    width: 1302px;
  }
}
</style>
